@tailwind base;
@tailwind components;
@tailwind utilities;



body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.scrollbar-hide::-webkit-scrollbar {
  display: none;
}



.scrollbar-hide {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}


* {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

*::-webkit-scrollbar {
  display: none; 
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}


